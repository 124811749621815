import { useContext } from "react";

import { togglePanel } from "../../utils/helpers";

import ConfiguratorContext from "../../ConfiguratorContext";

import "./index.scss";

const AccordionHeading = (props) => {
  const { name, sectionTitle } = props.section;
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const isExpanded =
    configuratorState[name] && configuratorState[name].expanded;
  return (
    <h3>
      <button
        aria-expanded={isExpanded}
        className="accordion-heading"
        type="button"
        onClick={() => {
          togglePanel(name, configuratorState, setConfiguratorState);
        }}
      >
        <span className="accordion-heading-title">{sectionTitle}</span>
        <span>Wijzigen</span>
      </button>
    </h3>
  );
};

export default AccordionHeading;

import { useContext, useEffect, useState } from "react";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import { getDefaultOption } from "../../utils/helpers";
import { showNext } from "../../utils/show-next";

import "./index.scss";

const CardSelector = (props) => {
  const { name, options, shortTitle } = props.section;
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const [productConfigurationState, setProductConfigurationState] = useContext(
    ProductConfigurationContext
  );
  const [specifications, setSpecifications] = useState(null);

  function handleEvent(option) {
    // if this is not an accordion style display,
    // all panels are already expanded and visible
    if (configuratorState.metadata.accordion) {
      showNext({
        configuratorState,
        currentPanelName: name,
        setConfiguratorState,
      });
    }

    if (option.specifications) {
      setSpecifications(option.specifications);
    }

    setProductConfigurationState((prevState) => {
      return {
        ...prevState,
        [name]: {
          displayName: shortTitle,
          price: option.discountedPrice || option.price,
          selectedChoice: option.value,
        },
      };
    });
  }

  useEffect(() => {
    const defaultOption = getDefaultOption(options);
    if (defaultOption.specifications) {
      setSpecifications(defaultOption.specifications);
    }
  }, [options]);

  return (
    <div className="card-selector">
      <ul className="reset-list choices-container">
        {options.map((option) => {
          let isSelected = false;
          if (
            productConfigurationState[name] &&
            productConfigurationState[name].selectedChoice === option.value
          ) {
            isSelected = true;
          }

          return (
            <li
              key={option.id}
              className={
                isSelected ? "option-container selected" : "option-container"
              }
            >
              <div>
                <img
                  className="option-img"
                  src={`./assets/${option.image}`}
                  width="100"
                  height="100"
                  alt={option.imageAlt}
                />
                <label htmlFor={option.id}>
                  <input
                    id={option.id}
                    onChange={() => {
                      handleEvent(option);
                    }}
                    type="radio"
                    name={name}
                    defaultChecked={option.default}
                  />
                  {option.value}
                </label>
              </div>
            </li>
          );
        })}
      </ul>
      {specifications && (
        <>
          <h3>Specificaties van de gekozen beker</h3>
          <p>{specifications}</p>
        </>
      )}
    </div>
  );
};

export default CardSelector;

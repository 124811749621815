import { useContext } from "react";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import { showNext } from "../../utils/show-next";

import "./index.scss";

const QuantitySelector = (props) => {
  const { name, options, sectionTitle } = props.section;
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const [, setProductConfigurationState] = useContext(
    ProductConfigurationContext
  );

  function handleEvent(option) {
    // if this is not an accordion style display,
    // all panels are already expanded and visible
    if (configuratorState.metadata.accordion) {
      showNext({
        configuratorState,
        currentPanelName: name,
        setConfiguratorState,
      });
    }

    if (option.applyDiscount && configuratorState[option.applyDiscount.key]) {
      const section = configuratorState[option.applyDiscount.key];
      const discountPercentage = option.applyDiscount.percentage;

      for (let item of section.options) {
        const discount = (discountPercentage / 100) * item.price;
        item.discountedPrice = item.price - discount;
      }

      setConfiguratorState((prevState) => {
        return {
          ...prevState,
          [option.applyDiscount.key]: section,
        };
      });

      setProductConfigurationState((prevState) => {
        const discount =
          (discountPercentage / 100) *
          Number(prevState[option.applyDiscount.key].price);
        return {
          ...prevState,
          [option.applyDiscount.key]: {
            displayName: prevState[option.applyDiscount.key].displayName,
            price: prevState[option.applyDiscount.key].price,
            discountedPrice:
              prevState[option.applyDiscount.key].price - discount,
            selectedChoice: prevState[option.applyDiscount.key].selectedChoice,
          },
        };
      });
    }

    setProductConfigurationState((prevState) => {
      return {
        ...prevState,
        [name]: {
          displayName: sectionTitle,
          price: option.price,
          selectedChoice: option.value,
        },
      };
    });
  }

  return (
    <div className="quantity-selector">
      <p>Kies hier het gewenste aantal</p>
      <ul className="reset-list quantity-choices-container">
        {options.map((option) => (
          <li key={option.id}>
            <label htmlFor={option.id}>
              <input
                id={option.id}
                onChange={() => {
                  handleEvent(option);
                }}
                type="radio"
                name="quantity"
                defaultChecked={option.default}
              />
              {option.value}{" "}
              {option.applyDiscount && option.applyDiscount.percentage > 0 && (
                <span className="discount">
                  {`${option.applyDiscount.percentage}% discount`}
                </span>
              )}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuantitySelector;

import { useContext } from "react";

import ConfiguratorContext from "../../ConfiguratorContext";

import CardSelector from "../card-selector";
import SimpleSelector from "../simple-selector";

import ColorSelector from "../color-selector";
import DeliveryTimeSelector from "../delivery-time-selector";
import DesignSelector from "../design-selector";
import MaterialSelector from "../material-selector";
import SizeSelector from "../size-selector";
import QuantitySelector from "../quantity-selector";

import "./index.scss";

const AccordionPanel = (props) => {
  const { component, name } = props.section;
  const [configuratorState] = useContext(ConfiguratorContext);
  const isExpanded =
    configuratorState[name] && configuratorState[name].expanded;

  return (
    <section
      className={isExpanded ? "accordion-panel show-panel" : "accordion-panel"}
    >
      {component === "card" && <CardSelector section={props.section} />}
      {component === "simple" && <SimpleSelector section={props.section} />}
      {name === "quantity" && <QuantitySelector section={props.section} />}
      {name === "material" && <MaterialSelector section={props.section} />}
      {name === "size" && <SizeSelector section={props.section} />}
      {name === "color" && <ColorSelector section={props.section} />}
      {name === "deliveryTime" && (
        <DeliveryTimeSelector section={props.section} />
      )}
      {name === "design" && (
        <DesignSelector
          metadata={configuratorState.metadata}
          section={props.section}
        />
      )}
    </section>
  );
};

export default AccordionPanel;

import * as React from "react";

import "./index.scss";

const RequestDesignModal = (props) => {
  const { allowedImageExtensions, uploaderUrl } = props.section;

  const [errorMessage, setErrorMessage] = React.useState("");

  const checkUploadFile = (event) => {
    const fileName = event.target.value;
    const fileExtension = fileName
      .substr(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (!allowedImageExtensions.includes(fileExtension)) {
      setErrorMessage(
        `Ongeldig afbeeldingstype geupload. Gebruik een van de volgende extensies: ${allowedImageExtensions}`
      );
      event.target.value = "";
      return;
    }

    setErrorMessage("");
  };

  const setDesignOptions = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    fetch(uploaderUrl, {
      method: "POST",
      headers: { "X-Requested-With": "XMLHttpRequest" },
      body: formData,
      cache: "no-cache",
      contentType: false,
      processData: false,
      dataType: "json",
    })
      .then((response) => response.json())
      .then((data) => {
        let description = [];

        for (let i = 0; i < data["files"].length; i++) {
          const item = data["files"][i];
          description.push(
            `Bestand ${i + 1}|<a target='_blank' href='${
              item["src"]
            }'>Afbeelding</a>`
          );
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  return (
    <form
      id="webform_fvokpc"
      method="post"
      encType="multipart/form-data"
      onSubmit={setDesignOptions}
    >
      <fieldset>
        <legend>Stap 1: Kenmerk</legend>
        <label htmlFor="product-design-name">Bestand naam *</label>
        <input
          id="product-design-name"
          type="text"
          name="filename"
          placeholder="Bijvoorbeeld: {bedrijfsnaam} vlag2016"
          required
        />
        <p className="input-details">
          Specificeer een unieke naam voor het ontwerp van uw product. Zo kunnen
          wij uw ontwerp eenvoudig terug vinden in ons systeem.
        </p>
      </fieldset>
      <fieldset>
        <legend>Stap 2: Logo bestanden of afbeeldingen</legend>
        {errorMessage && <div className="error">{errorMessage}</div>}
        <label htmlFor="file001">Afbeelding 1</label>
        <input
          id="file001"
          type="file"
          name="figure1"
          onChange={checkUploadFile}
          required
        />
        <p className="input-details">
          {`De volgende bestandstypes zijn toegstaan: ${allowedImageExtensions}`}
        </p>

        <label htmlFor="file002">Afbeelding 2</label>
        <input
          id="file002"
          type="file"
          name="figure2"
          onChange={checkUploadFile}
        />
        <p className="input-details">
          {`De volgende bestandstypes zijn toegstaan: ${allowedImageExtensions}`}
        </p>
      </fieldset>
      <fieldset>
        <legend>Stap 3: Omschrijving ontwerp</legend>
        <label htmlFor="instructions">Instructies *</label>
        <textarea
          id="instructions"
          name="instructions"
          rows="5"
          placeholder="Bijvoorbeeld: Logo1 beeldvullend in het midden van de vlag. Logo2 in het klein, rechtsonder in de hoek."
          spellCheck="false"
          required
        />
        <p className="input-details">
          Geef uw wensen en ideeën aan onze ontwerpers door. Zo zorgen we ervoor
          dat het ontwerp aan uw verwachting voldoet.
        </p>
      </fieldset>
      <p className="footnote">* Verplichte velden</p>
      <button type="submit" className="action primary tocart">
        Opslaan
      </button>
    </form>
  );
};

export default RequestDesignModal;

import * as React from "react";
import { useContext } from "react";
import Modal from "react-modal";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import { showSummary } from "../../utils/helpers";
import RequestDesignModal from "../../organisms/request-design-modal";

const DesignSelector = (props) => {
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const [, setProductConfigurationState] = useContext(
    ProductConfigurationContext
  );
  const { storeName } = props.metadata;
  const { name, options, sectionTitle } = props.section;

  const [customModalIsOpen, setCustomModalIsOpen] = React.useState(false);

  Modal.setAppElement("#root");

  function closeModal(modal) {
    if (modal === "custom") {
      setCustomModalIsOpen(false);
      showSummary(configuratorState, setConfiguratorState);
    }
  }

  function openModal(option) {
    if (option.value === "custom") {
      setCustomModalIsOpen(true);
      setProductConfigurationState((prevState) => {
        return {
          ...prevState,
          [name]: {
            displayName: sectionTitle,
            price: option.price,
            selectedChoice: option.value,
          },
        };
      });
    }
  }

  return (
    <>
      <ul className="design-selector">
        {options.map((option) => (
          <li key={option.id}>
            <h3>
              Het ontwerp uitbesteden aan DTP specialisten van {storeName}
            </h3>
            <p>
              U heeft geen ontwerp en wilt deze ook niet zelf maken? Onze DTP
              specialisten helpen u graag voor slechts €{" "}
              {Number.parseInt(option.price, 10).toFixed(2)}.
            </p>
            <button
              type="button"
              className="action primary tocart"
              onClick={() => {
                openModal(option);
              }}
            >
              Laat {storeName} Ontwerpen
            </button>
          </li>
        ))}
      </ul>

      <Modal
        isOpen={customModalIsOpen}
        onRequestClose={() => {
          closeModal("custom");
        }}
        overlayClassName="modal-overlay"
        contentLabel={`Laat ${storeName} Ontwerpen`}
        className="modal design-modal"
      >
        <button
          type="button"
          className="close-modal"
          onClick={() => {
            closeModal("custom");
          }}
        >
          <span className="visually-hidden">Sluiten</span>
        </button>
        <RequestDesignModal section={props.section} />
      </Modal>
    </>
  );
};

export default DesignSelector;

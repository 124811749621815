import * as React from "react";

import Accordion from "./organisms/accordion";
import AccordionEntry from "./organisms/accordion-entry";
import Overview from "./organisms/overview";
import Summary from "./organisms/summary";

import ConfiguratorContext from "./ConfiguratorContext";
import ProductConfigurationContext from "./ProductConfigurationContext";

import { getDefaultProductConfiguration } from "./utils/helpers";

const Configurator = ({ config }) => {
  const configuratorContext = React.useState(config);
  const productConfigurationContext = React.useState(
    getDefaultProductConfiguration(config)
  );
  const [configuratorState, setConfiguratorState] = configuratorContext;
  const [, setProductConfiguratorState] = productConfigurationContext;

  React.useEffect(() => {
    setConfiguratorState(config);
    setProductConfiguratorState(getDefaultProductConfiguration(config));
  }, [config, setConfiguratorState, setProductConfiguratorState]);

  return (
    <ConfiguratorContext.Provider value={configuratorContext}>
      <ProductConfigurationContext.Provider value={productConfigurationContext}>
        <div className="configurator-container">
          <div className="configurator-main">
            <Accordion>
              {Object.keys(configuratorState).map((entry) => {
                const section = configuratorState[entry];

                return (
                  section.type !== "metadata" &&
                  section.type !== "summary" && (
                    <AccordionEntry key={section.id} section={section} />
                  )
                );
              })}
            </Accordion>
            <Summary />
          </div>
          <Overview />
        </div>
      </ProductConfigurationContext.Provider>
    </ConfiguratorContext.Provider>
  );
};

export default Configurator;

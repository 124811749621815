const getNextPanelName = (currentPanelName, configuratorState) => {
  const currentPanelIndex =
    Object.keys(configuratorState).indexOf(currentPanelName);

  if (currentPanelIndex) {
    return Object.keys(configuratorState)[currentPanelIndex + 1];
  } else {
    throw new Error(
      `Panel name ${currentPanelName} does not exist in configuratorState`
    );
  }
};

export const showNext = (props) => {
  const { configuratorState, currentPanelName, setConfiguratorState } = props;
  const nextPanelName = getNextPanelName(currentPanelName, configuratorState);

  setConfiguratorState((prevState) => {
    return {
      ...prevState,
      [currentPanelName]: {
        ...configuratorState[currentPanelName],
        expanded: false,
      },
      [nextPanelName]: {
        ...configuratorState[nextPanelName],
        expanded: true,
      },
    };
  });
};

import { useContext } from "react";

import { calculateTotal } from "../../utils/helpers";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import "./index.scss";

const Summary = () => {
  const [configuratorState] = useContext(ConfiguratorContext);
  const [productConfigurationState] = useContext(ProductConfigurationContext);

  function getFormattedPrice(price) {
    if (price === 0) {
      return "Geen extra kosten";
    }

    const currencySymbol = configuratorState.metadata.currencySymbol;
    return currencySymbol + " " + Number.parseFloat(price).toFixed(2);
  }

  return (
    <div className="summary">
      <h3>Samenvatting</h3>
      <table className="summary-table">
        <thead>
          <tr>
            <th>Productoptie</th>
            <th>Geselecteerde keuze</th>
            <th>Prijs</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(productConfigurationState).map((entry) => {
            return (
              <tr key={entry}>
                <td>{productConfigurationState[entry].displayName}</td>
                <td>{productConfigurationState[entry].selectedChoice}</td>
                <td>
                  {productConfigurationState[entry].discountedPrice
                    ? getFormattedPrice(
                        productConfigurationState[entry].discountedPrice
                      )
                    : getFormattedPrice(productConfigurationState[entry].price)}
                  {entry === "model"
                    ? ` per ${configuratorState.metadata.baseQuantity} stuk`
                    : ""}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="2">Totaal</td>
            <td>{`${configuratorState.metadata.currencySymbol} ${calculateTotal(
              productConfigurationState,
              configuratorState.metadata
            )}`}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">
              <button type="button" className="primary">
                Voeg toe aan winkelwagen
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Summary;

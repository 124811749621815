import { useContext } from "react";

import { calculateTotal } from "../../utils/helpers";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import "./index.scss";

const Overview = () => {
  const [configuratorState] = useContext(ConfiguratorContext);
  const [productConfigurationState] = useContext(ProductConfigurationContext);

  return (
    <div className="configurator-overview">
      <h3>Overzicht</h3>
      {Object.keys(productConfigurationState).length > 0 && (
        <>
          <ul>
            {Object.keys(productConfigurationState).map((entry) => {
              const selection = productConfigurationState[entry];
              return (
                <li key={entry}>
                  <h4>{selection.displayName}</h4>
                  <p>{selection.selectedChoice}</p>
                </li>
              );
            })}
          </ul>
          <h3>Totaalbedrag</h3>
          <p>{`${configuratorState.metadata.currencySymbol} ${calculateTotal(
            productConfigurationState,
            configuratorState.metadata
          )}`}</p>
        </>
      )}
    </div>
  );
};

export default Overview;

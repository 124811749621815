export const calculateTotal = (productConfigurationState, metadata) => {
  let totalCost = 0;
  let selectedQuantity = 0;

  for (const option in productConfigurationState) {
    if (option === "quantity") {
      selectedQuantity = productConfigurationState[option].selectedChoice;
    }

    if (option === "model") {
      const price =
        productConfigurationState[option].discountedPrice ||
        productConfigurationState[option].price;

      totalCost += price * (selectedQuantity / metadata.baseQuantity);
    } else {
      totalCost +=
        productConfigurationState[option].discountedPrice ||
        productConfigurationState[option].price;
    }
  }

  return Number.parseFloat(totalCost).toFixed(2);
};

export const collapseAll = (
  panelName,
  configuratorState,
  setConfiguratorState
) => {
  for (const stateEntry of Object.keys(configuratorState)) {
    if (stateEntry !== panelName) {
      setConfiguratorState((prevState) => {
        return {
          ...prevState,
          [stateEntry]: {
            ...configuratorState[stateEntry],
            id: prevState[stateEntry].id,
            expanded: false,
            name: prevState[stateEntry].name,
            options: prevState[stateEntry].options,
            sectionTitle: prevState[stateEntry].sectionTitle,
            visible: prevState[stateEntry].visible,
          },
        };
      });
    }
  }
};

export const togglePanel = (
  panelName,
  configuratorState,
  setConfiguratorState
) => {
  // collapse all panels that is not the current panel
  collapseAll(panelName, configuratorState, setConfiguratorState);
  // ensure that the current panel is expanded
  setConfiguratorState((prevState) => {
    return {
      ...prevState,
      [panelName]: {
        ...configuratorState[panelName],
        id: prevState[panelName].id,
        expanded: true,
        name: prevState[panelName].name,
        options: prevState[panelName].options,
        sectionTitle: prevState[panelName].sectionTitle,
        visible: prevState[panelName].visible,
      },
    };
  });
};

export const showSummary = (configuratorState, setConfiguratorState) => {
  // only collapse all if this is an accordion style configurator
  if (configuratorState.metadata.accordion) {
    collapseAll(null, configuratorState, setConfiguratorState);
  }

  setConfiguratorState((prevState) => {
    return {
      ...prevState,
      summary: {
        visible: true,
        type: "summary",
      },
    };
  });
};

export const getDefaultOption = (options) => {
  return options.find((option) => option.default);
};

export const getDefaultProductConfiguration = (json) => {
  const defaultProductConfiguration = {};

  for (const entry in json) {
    const { basePrice, options, shortTitle } = json[entry];

    if (entry !== "metadata" && entry !== "summary" && entry !== "design") {
      const defaultOption = getDefaultOption(options);

      let price = defaultOption.price;

      if (basePrice) {
        price = Number.parseFloat(defaultOption.value * basePrice);
      }

      defaultProductConfiguration[entry] = {
        displayName: shortTitle,
        price: price,
        selectedChoice: defaultOption.value,
      };
    }
  }

  return defaultProductConfiguration;
};

export const getFormattedDeliveryDate = (deliveryTime) => {
  // Get the current number of days and add at least one day for production.
  const days = Number.parseInt(deliveryTime, 10) + 1;
  const deliveryDate = new Date();
  const formatter = new Intl.DateTimeFormat(document.documentElement.lang, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  if (typeof days !== "undefined" && days > 0) {
    const today = new Date();

    deliveryDate.setDate(today.getDate() + days);

    // If one of the days between today and the estimated
    // delivery date is a Sunday, we will need to add one more
    // day for delivery.
    while (today < deliveryDate) {
      const day = today.getDay();

      if (day === 0) {
        deliveryDate.setDate(deliveryDate.getDate() + 1);
        break;
      }

      today.setDate(today.getDate() + 1);
    }
  }
  return `Bezorgd op ${formatter.format(deliveryDate)}`;
};

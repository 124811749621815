import { useContext } from "react";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import { showNext } from "../../utils/show-next";

import "./index.scss";

const SimpleSelector = (props) => {
  const { basePrice, labels, name, options, sectionTitle } = props.section;
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const [, setProductConfigurationState] = useContext(
    ProductConfigurationContext
  );

  function handleEvent(option) {
    // if this is not an accordion style display,
    // all panels are already expanded and visible
    if (configuratorState.metadata.accordion) {
      showNext({
        configuratorState,
        currentPanelName: name,
        setConfiguratorState,
      });
    }

    let price = option.price;

    if (basePrice) {
      price = Number.parseFloat(option.value * basePrice);
    }

    setProductConfigurationState((prevState) => {
      return {
        ...prevState,
        [name]: {
          displayName: sectionTitle,
          price: price,
          selectedChoice: option.value,
        },
      };
    });
  }

  return (
    <div className="simple-selector">
      <ul className="reset-list choices-container">
        {options.map((option) => (
          <li key={option.id}>
            <label htmlFor={option.id}>
              <input
                id={option.id}
                onChange={() => {
                  handleEvent(option);
                }}
                type="radio"
                name={name}
                defaultChecked={option.default}
              />
              {`${option.value} ${
                labels !== undefined
                  ? option.value > 1
                    ? labels.plural
                    : labels.singular
                  : ""
              }`}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SimpleSelector;

import { useContext } from "react";

import ConfiguratorContext from "../../ConfiguratorContext";
import ProductConfigurationContext from "../../ProductConfigurationContext";

import { showNext } from "../../utils/show-next";
import { getFormattedDeliveryDate } from "../../utils/helpers";

import "./index.scss";

const DeliveryTimeSelector = (props) => {
  const { name, options, sectionTitle } = props.section;
  const [configuratorState, setConfiguratorState] =
    useContext(ConfiguratorContext);
  const [, setProductConfigurationState] = useContext(
    ProductConfigurationContext
  );

  function handleEvent(option) {
    showNext({
      configuratorState,
      currentPanelName: name,
      setConfiguratorState,
    });
    setProductConfigurationState((prevState) => {
      return {
        ...prevState,
        [name]: {
          displayName: sectionTitle,
          price: option.price,
          selectedChoice: option.value,
        },
      };
    });
  }

  return (
    <div className="delivery-time-selector">
      <p>Kies hier het gewenste aflevertijd</p>
      <ul className="reset-list delivery-time-choices-container">
        {options.map((option) => (
          <li key={option.id}>
            <div className="option-container">
              <div className="img-container">
                <img
                  src={`./assets/${option.image}`}
                  width="100"
                  height="100"
                  alt={option.imageAlt}
                />
              </div>
              <div className="product-description">
                <label htmlFor={option.id}>
                  <input
                    id={option.id}
                    onChange={() => {
                      handleEvent(option);
                    }}
                    type="radio"
                    name="delivery-time"
                    defaultChecked={option.default}
                  />
                  {option.value > 1
                    ? `${option.value} Days (${option.price})`
                    : `${option.value} Day (${option.price})`}
                </label>
                <p>{getFormattedDeliveryDate(option.value)}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DeliveryTimeSelector;

import AccordionHeading from "../../molecules/accordion-heading";
import AccordionPanel from "../../molecules/accordion-panel";

import "./index.scss";

const AccordionEntry = (props) => {
  const { section } = props;
  return (
    <li className="accordion-item">
      <AccordionHeading section={section} />
      <AccordionPanel section={section} />
    </li>
  );
};

export default AccordionEntry;

import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Configurator from "./Configurator";

import generic from "./data/config.json";
import cups from "./data/cups.json";

import "./sass/product-configurator.scss";
import "./app.scss";

const App = () => {
  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/">Generic</Link>
          </li>
          <li>
            <Link to="/cups">Cups</Link>
          </li>
        </ul>
      </nav>
      <div className="container">
        <Switch>
          <Route exact path="/">
            <Configurator config={generic} />
          </Route>
          <Route path="/cups">
            <Configurator config={cups} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
